<template>
    <ORowContainer>
        <div v-if="Object.keys(data).length > 0">
            <Checklist :data="data" />
        </div>
    </ORowContainer>
</template>

<script setup>
import Checklist from 'o365.vue.components.Checklists.Checklist2.vue'
import { nextTick } from 'vue';

const props = defineProps({
    data: {
        type: Object,
        required: true
    }
});

async function createChecklist() {
    const checklists = {
        'name': '',
        'checklistItems': ''
    }
    let index

    if (Object.keys(props.data).length > 0) {
        index = props.data.checklists.push(checklists) - 1
    } else {
        props.data['checklists'] = [checklists]
        index = 0
    }

    await nextTick()
    document.getElementById(`checklist_${index}`).focus()
}

defineExpose({ createChecklist })

window.data = props.data
</script>